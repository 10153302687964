<div class="choose">
  <div class="choose-item">
    <div class="icon">
      <svg>
        <use attr.href="./assets/icons.svg#{{select?.icon1}}"></use>
      </svg>
    </div>
    <div class="text">{{select?.text1}}</div>
  </div>
  <div class="choose-item">
    <div class="icon">
      <svg>
        <use attr.href="./assets/icons.svg#{{select?.icon2}}"></use>
      </svg>
    </div>
    <div class="text">{{select?.text2}}</div>
  </div>
</div>
