import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nb-choose-state',
  templateUrl: './choose-state.component.html',
  styleUrls: ['./choose-state.component.scss']
})
export class ChooseStateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
