<nb-header
  headingSecondary="About your company"
  headingMain="Details about your company's name and description."
></nb-header>

<p>
  Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut, blanditiis.
  Voluptate, id officia, expedita nam provident mollitia eaque veritatis quo
  nulla dolorum delectus aperiam velit voluptas quisquam! Consequuntur,
  dignissimos odio.
</p>
