import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'nb-business-details-about',
  templateUrl: './business-details-about.component.html',
  styleUrls: ['./business-details-about.component.scss']
})
export class BusinessDetailsAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
