<nb-header
  headingSecondary="Choose the state"
  headingMain="Select the state in which you want to incorporate your new company."
></nb-header>

<nb-choose [select]="{
  text1: 'Delaware',
  text2: 'Wyoming',
  icon1: 'icon-home1',
  icon2: 'icon-location'
}">
</nb-choose>
