<nb-header
  headingSecondary="Choose the type of company"
  headingMain="Creating your company in US is just a few steps away, Enter your details to continue."
></nb-header>

<nb-choose [select]="{
  text1: 'LLC',
  text2: 'Corporation',
  icon1: 'icon-building-o',
  icon2: 'icon-text-document'
}">
</nb-choose>
